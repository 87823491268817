export const PastEvents = (props) => {
  console.log(props)
  return (
    <div id={props.id} className='events text-center'>
      <div className='container-fluid'>
        <div className='justify-content-center event-container'>
          <h2>{props.title}</h2>
          {props.data
            ? props.data.data.map((d, i) => (
              <div className="col-12 col-sm-6 col-md-3 event-card" key={i}>
                <img className={d.class} src={d.img} alt={d.title} />
                <div className="past-card-body">
                  <h5 className="event-title card-title">
                    <p dangerouslySetInnerHTML={{ __html: d.title }} />
                  </h5>
                  <p className="card-text">{d.date}</p>
                </div>
              </div>
            ))
            : null}
          {/* <p>To be announced soon.</p> */}
        </div>
      </div>
    </div>
  )
}