import { SocialIcon } from 'react-social-icons';


function replaceWithStrongTags(inputString) {
  return inputString.replace(/"([^"]*)"/g, '<strong class="bold-text">$1</strong>');
}


export const About = (props) => {

  var p = props.data ? props.data.paragraph : "loading";
  p = replaceWithStrongTags(p);

  return (
    <div id="about">
      <div className="about-details">
        <div className="about-text">
          <h2>About Us</h2>
          <p dangerouslySetInnerHTML={{ __html: p }} />
          <div className="list-style">
          </div>
        </div>
        <div id="followus">
          <SocialIcon className="socialIcon" bgColor="white" target="_blank" rel="noreferrer" url="https://www.facebook.com/patoghmunich" />
          <SocialIcon className="socialIcon" bgColor="white" target="_blank" rel="noreferrer" url="https://instagram.com/seemfest" />
          <SocialIcon className="socialIcon" bgColor="white" target="_blank" rel="noreferrer" url="https://t.me/PatoghMunich" />
          <SocialIcon className="socialIcon" bgColor="white" target="_blank" rel="noreferrer" url="https://soundcloud.com/patoghmunich" />
        </div>
      </div>
    </div>
  );
};
