import { PieChart, Pie, Cell, Tooltip, Legend } from "recharts";


export const Sponsors = (props) => {
  return (
    <div id='sponsors'>
      <div className="container-fluid">
        <h2>Sponsors</h2>
        <div className="about-text">
          <p className="sponsors-p">Our milestone is to promote transparency in the realm of culture, and therefore the above pie-chart shows the contribution of different sources to fund the SEEM festival project. SEEM festival is financed by our grants, sponsorship and the donation and grass root support of our non-profit association Patogh e.V. from those who resonate with our visions.</p>
          <p> Should you feel on board with our visions, we would be honored to receive your donations transferred to our bank account:</p>
          <div className="bank-account">
            <p>Patogh e.V.</p>
            <p>IBAN: DE19 7015 0000 1005 8023 33 <br />BIC: SSKMDEMMXXX <br />Stadtsparkasse München <br />Reference/Verwendungszweck: Patogh-Spende </p>
          </div>
        </div>
        <div className="text-center">

          <PieChart width={400} height={400} className="funding-chart">
            <Pie
              data={props.data.funding}
              cx="50%"
              cy="50%"
              fill="#8884d8"
              dataKey="value"
              label
            >
              {props.data.funding.map(d => (
                <Cell key={`cell-${d.name}`} fill={d.color} />
              ))}
            </Pie>
            <Tooltip />
            <Legend />
          </PieChart>

          <div className="row justify-content-center">
            {props.data.providers
              ? props.data.providers.filter(d => d.active).map((d, i) => (
                <div key={`${d.name}-${i}`} className='col-md-2 col-sm-2'>
                  <a href={d.link} target="_blank" rel="noreferrer"><img src={d.img} alt='...' className={d.classname} id={d.id} /></a>
                </div>
              ))
              : 'loading'}
          </div>
        </div>
      </div>
    </div>
  )
}