import { Header } from "./components/header";
import { About } from "./components/about";
import { Sponsors } from "./components/sponsors";
import { Artists } from "./components/artists";
import { Events } from "./components/events";
import { PastEvents } from "./components/pastevents";
import { Schedule } from "./components/schedule";
import { Contact } from "./components/contact";
import {Impressum} from "./components/impressum"
import SmoothScroll from "smooth-scroll";
import JsonData from "./data/data.json";

import "./App.css";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 500,
  speedAsDuration: false,
});

const App = () => {

  return (
    <div>

      <Header data={JsonData.Header} />
      <Events data={JsonData.Events.Current}/>

      <About data={JsonData.About} />

      <PastEvents data={JsonData.Events.Past}/>
      <Artists data={JsonData.Artists} />

      <Schedule data={JsonData.Schedule} />

      <Sponsors data={JsonData.Sponsors} />
      <Contact data={JsonData.Contact} />
      <Impressum/>

    </div>
  );
};

export default App;