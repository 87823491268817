export const Events = (props) => {
  return (
    <div id={props.data.id} className='events text-center'>
      <div className='container-fluid'>
        <div className="d-flex justify-content-center flex-wrap gap-2 event-container">
          <h2>{props.title}</h2>
          {props.data
            ? props.data.data.map((d, i) => (
              <div className="col-12 col-sm-6 col-md-3 event-card" key={i}>
                <img className={d.class} src={d.img} alt={d.title} />
                <div className="card-body">
                  <h5 className="event-title card-title">
                    <p dangerouslySetInnerHTML={{ __html: d.title }} />
                  </h5>
                  {!d.past && (
                    <>
                      <h5 className="card-text">{d.price}</h5>
                      <p className="card-text">{d.date}</p>
                      <p className="card-text">
                        <small className="text-muted">{d.location}</small>
                      </p>
                      {d.link !== "no-ticket" ? (
                        <a href={d.link} target="_blank" rel="noreferrer" className="btn btn-primary">
                          {d.ticket}
                        </a>
                      ) : null}
                    </>
                  )}
                </div>
              </div>
            ))
            : null}
          {/* <p>To be announced soon.</p> */}
        </div>
      </div>
    </div>
  )
}