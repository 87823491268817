export const Contact = () => {
  return (
    <div id='footer'>
      <div className='container-fluid text-center'>
        <h5>
          For more details please contact info[at]patoghmunich.com
        </h5>
        <p>
          © Patogh e.V. 2025
        </p>
      </div>
    </div>
  )
}
